<template>
    <div id="subscriptionManagement" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="subscription"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle
                                subHeaderDescKey="subscription_subscriptionManagementSubTitle"
                            ></app-sitetitle>
                        </div>
                        <!-- end:: Content Head -->

                        <!-- begin:: Content -->
                        <div class="kt-content kt-grid__item kt-grid__item--fluid">
                            <!-- begin:: Content -->

                            <div
                                v-if="requestSucceeded && !this.localSubscription"
                                class="row marginRowLeftAndRightZero"
                            >
                                <div class="col-lg-12 alert alert-light alert-elevate" role="alert">
                                    <div class="alert-icon">
                                        <i class="flaticon-gift kt-font-brand"></i>
                                    </div>
                                    <div class="alert-text">
                                        <span>{{ $t("subscription_offerLine1") }}</span>
                                    </div>
                                    <div class="alert-close">
                                        <a
                                            href="#"
                                            @click="startTrialSubscriptionButton"
                                            style="width: 200px;"
                                            class="btn btn-primary btn-outline btn-sm getPaidAccountButton"
                                        >
                                            <i class="fa fa-mouse-pointer" style="color: white;"></i>
                                            {{ $t("subscription_start3MonthTrial") }}
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div
                                        v-if="requestSucceeded && this.localSubscription"
                                        class="kt-portlet"
                                    >
                                        <div class="kt-portlet__body kt-portlet__body--fit">
                                            <!--begin::Widget -->
                                            <div class="kt-widget kt-widget--project-1">
                                                <div class="kt-widget__head">
                                                    <div class="kt-widget__label">
                                                        <div
                                                            class="kt-widget__info kt-margin-t-5"
                                                            style="padding-left: 0;"
                                                        >
                                                            <a
                                                                class="kt-widget__title"
                                                                style="color: #5d78ff"
                                                            >{{ this.localSubscription.subRef }}</a>
                                                            <span
                                                                class="kt-widget__desc"
                                                            >{{ $t("common_omniaccessSub") }}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="kt-widget__body">
                                                    <div class="kt-widget__stats">
                                                        <div class="kt-widget__item">
                                                            <span
                                                                class="kt-widget__date"
                                                            >{{ $t("common_startDate") }}</span>
                                                            <div class="kt-widget__label">
                                                                <span
                                                                    id="startDateStringLabel"
                                                                    class="btn btn-label-brand btn-sm btn-bold btn-upper"
                                                                >{{ this.startDateString }}</span>
                                                            </div>
                                                        </div>

                                                        <div class="kt-widget__item">
                                                            <span
                                                                class="kt-widget__date"
                                                            >{{ $t("common_dueDate") }}</span>
                                                            <div class="kt-widget__label">
                                                                <span
                                                                    id="endDateStringLabel"
                                                                    class="btn btn-label-danger btn-sm btn-bold btn-upper"
                                                                >{{ this.dueDateString }}</span>
                                                            </div>
                                                        </div>

                                                        <div class="kt-widget__item flex-fill">
                                                            <span
                                                                class="kt-widget__subtitel"
                                                            >{{ $t("common_progress") }}</span>
                                                            <div
                                                                class="kt-widget__progress d-flex align-items-center"
                                                            >
                                                                <div
                                                                    class="progress"
                                                                    style="height: 7px;width: 40%;"
                                                                >
                                                                    <div
                                                                        class="progress-bar"
                                                                        :class="{ 'kt-bg-success': progressFromStart <= 80, 'kt-bg-warning': progressFromStart >= 81 && progressFromStart <= 95, 'kt-bg-danger': progressFromStart > 95 }"
                                                                        role="progressbar"
                                                                        :style="{ width: progressFromStart + '%' }"
                                                                        aria-valuenow="100"
                                                                        aria-valuemin="0"
                                                                        aria-valuemax="100"
                                                                    ></div>
                                                                </div>
                                                                <span
                                                                    class="kt-widget__stat"
                                                                >{{ this.progressFromStart }}%</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div
                                                        class="kt-widget__content"
                                                        style=" margin-top: 15px; "
                                                    >
                                                        <div
                                                            v-if="this.localSubscription.type === 'PAID'"
                                                            class="kt-widget__details"
                                                        >
                                                            <span
                                                                class="kt-widget__subtitle"
                                                            >{{ $t("common_customerName") }}</span>
                                                            <span
                                                                class="kt-widget__value"
                                                            >{{ this.localSubscription.customerName }}</span>
                                                        </div>

                                                        <div
                                                            v-if="this.localSubscription.type === 'PAID'"
                                                            class="kt-widget__details"
                                                        >
                                                            <span
                                                                class="kt-widget__subtitle"
                                                            >{{ $t("common_customerCountry") }}</span>
                                                            <span
                                                                class="kt-widget__value"
                                                            >{{ this.customerCountryString }}</span>
                                                        </div>

                                                        <div class="kt-widget__details">
                                                            <span
                                                                class="kt-widget__subtitle"
                                                            >{{ $t("common_activeLicense") }}</span>

                                                            <span
                                                                id="totalM2StringLabel"
                                                                class="kt-widget__value"
                                                            >{{ this.getActiveLicense.totalM2 }} {{$t("common_sqM")}}</span>
                                                        </div>
                                                    </div>

                                                    <div
                                                        v-if="requestSucceeded && (this.getWaitingForActivationAddonLicense|| this.getWaitingForActivationRenewalLicense)"
                                                        class="kt-separator kt-separator--border-dashed"
                                                    ></div>

                                                    <span
                                                        v-if="requestSucceeded && (this.getWaitingForActivationAddonLicense|| this.getWaitingForActivationRenewalLicense)"
                                                        class="kt-widget__text"
                                                        style=" margin-top: 0px; margin-bottom: 20px; "
                                                    >{{ $t("subscription_modificationAreWaitingActivate") }}</span>

                                                    <div
                                                        v-if="requestSucceeded && (this.getWaitingForActivationAddonLicense || this.getWaitingForActivationRenewalLicense)"
                                                        class="table-responsive"
                                                        style="width: 100%;text-align: center;"
                                                    >
                                                        <table
                                                            class="table table-bordered table-head-solid"
                                                        >
                                                            <thead>
                                                                <tr>
                                                                    <th>{{ $t("subscription_natureOfTheChange") }}</th>
                                                                    <th>{{ $t("subscription_details") }}</th>
                                                                    <th>{{ $t("subscription_activation") }}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr
                                                                    v-if="this.getWaitingForActivationAddonLicense"
                                                                >
                                                                    <td
                                                                        style="vertical-align: middle;"
                                                                    >{{ $t("subscription_modificationOfTotalArea") }}</td>
                                                                    <td
                                                                        style="vertical-align: middle;"
                                                                    >
                                                                        <span>{{ $t("subscription_additionOfNSurfaceArea", { value: this.getWaitingForActivationAddonLicense.totalM2, unit: locale == "fr" ? "m2" : "sq/m" }) }}</span>
                                                                    </td>
                                                                    <td>
                                                                        <button
                                                                            type="button"
                                                                            @click="onActivateAddon"
                                                                            class="btn btn-sm btn-bold btn-brand"
                                                                        >{{ $t("subscription_activateAddon") }}</button>
                                                                    </td>
                                                                </tr>

                                                                <tr
                                                                    v-if="(this.getWaitingForActivationRenewalLicense && !this.getWaitingForActivationAddonLicense) || (this.getWaitingForActivationRenewalLicense && this.getWaitingForActivationAddonLicense && !this.isRenewalCanBeActivated)"
                                                                >
                                                                    <td
                                                                        style="vertical-align: middle;"
                                                                    >{{ $t("subscription_modificationRenew") }}</td>
                                                                    <td
                                                                        style="vertical-align: middle;"
                                                                    >
                                                                        <span>{{ $t("subscription_surfaceAvailableAfterRenew", { value: this.getWaitingForActivationRenewalLicense.totalM2, unit: locale == "fr" ? "m2" : "sq/m" } ) }}</span>
                                                                    </td>
                                                                    <td>
                                                                        <button
                                                                            type="button"
                                                                            @click="onActivateRenewal"
                                                                            :disabled="!this.isRenewalCanBeActivated"
                                                                            class="btn btn-sm btn-bold btn-brand"
                                                                        >{{ $t("subscription_renewSubscription") }}</button>
                                                                        <div
                                                                            v-if="!this.isRenewalCanBeActivated"
                                                                            style="margin-top: 5px; font-size: 12px;"
                                                                        >{{ $t("subscription_renewWillBeAvailableAfterDate", { date: this.dueDateString } ) }}</div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--end::Widget -->
                                        </div>
                                    </div>
                                    <!--Desactivate the option of paid susbscription deleting false if you want to activate it -->
                                    <div
                                        v-if="requestSucceeded && (!this.localSubscription || (this.localSubscription && this.localSubscription.type === 'TRIAL'))"
                                        class="kt-portlet"
                                    >
                                        <div class="kt-portlet__head">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                        width="24px"
                                                        height="24px"
                                                        viewBox="0 0 24 24"
                                                        version="1.1"
                                                        class="kt-svg-icon"
                                                    >
                                                        <g
                                                            stroke="none"
                                                            stroke-width="1"
                                                            fill="none"
                                                            fill-rule="evenodd"
                                                        >
                                                            <rect
                                                                x="0"
                                                                y="0"
                                                                width="24"
                                                                height="24"
                                                            />
                                                            <path
                                                                d="M18.1446364,11.84388 L17.4471627,16.0287218 C17.4463569,16.0335568 17.4455155,16.0383857 17.4446387,16.0432083 C17.345843,16.5865846 16.8252597,16.9469884 16.2818833,16.8481927 L4.91303792,14.7811299 C4.53842737,14.7130189 4.23500006,14.4380834 4.13039941,14.0719812 L2.30560137,7.68518803 C2.28007524,7.59584656 2.26712532,7.50338343 2.26712532,7.4104669 C2.26712532,6.85818215 2.71484057,6.4104669 3.26712532,6.4104669 L16.9929851,6.4104669 L17.606173,3.78251876 C17.7307772,3.24850086 18.2068633,2.87071314 18.7552257,2.87071314 L20.8200821,2.87071314 C21.4717328,2.87071314 22,3.39898039 22,4.05063106 C22,4.70228173 21.4717328,5.23054898 20.8200821,5.23054898 L19.6915238,5.23054898 L18.1446364,11.84388 Z"
                                                                fill="#000000"
                                                                opacity="0.3"
                                                            />
                                                            <path
                                                                d="M6.5,21 C5.67157288,21 5,20.3284271 5,19.5 C5,18.6715729 5.67157288,18 6.5,18 C7.32842712,18 8,18.6715729 8,19.5 C8,20.3284271 7.32842712,21 6.5,21 Z M15.5,21 C14.6715729,21 14,20.3284271 14,19.5 C14,18.6715729 14.6715729,18 15.5,18 C16.3284271,18 17,18.6715729 17,19.5 C17,20.3284271 16.3284271,21 15.5,21 Z"
                                                                fill="#000000"
                                                            />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3
                                                    class="kt-portlet__head-title"
                                                >{{ $t("subscription_activatePaidSubscription") }}</h3>
                                            </div>
                                        </div>

                                        <!--begin::Form-->
                                        <form class="kt-form" novalidate="novalidate">
                                            <div class="kt-portlet__body">
                                                <div class="kt-section">
                                                    <div
                                                        class="kt-section__content kt-section__content--solid"
                                                    >
                                                        <span
                                                            v-html="$t('subscription_alreadyHavePartner')"
                                                        ></span>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group validated">
                                                            <label
                                                                for="activateSubscriptionForm_subRefInput"
                                                            >{{ $t("subscription_subscriptionReference") }} *</label>
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text">
                                                                        <i class="la la-pencil"></i>
                                                                    </span>
                                                                </div>
                                                                <input
                                                                    type="text"
                                                                    class="form-control"
                                                                    v-model="vSubRef"
                                                                    @input="$v.vSubRef.$touch()"
                                                                    id="activateSubscriptionForm_subRefInput"
                                                                    :placeholder="$t('subscription_enterSubRef')"
                                                                />
                                                                <div
                                                                    v-if="!$v.vSubRef.required"
                                                                    class="invalid-feedback"
                                                                >{{ $t("error_fieldIsRequired") }}</div>
                                                            </div>
                                                            <span class="form-text text-muted">
                                                                <a
                                                                    class="kt-link"
                                                                    id="whereToFindSubscriptionReferencePopover"
                                                                    href="#"
                                                                    data-placement="top"
                                                                    data-toggle="kt-popover"
                                                                    data-html="true"
                                                                    title
                                                                    data-content="<img class='width100P' src='/assets/ale/whereToFindSubRef.png' />"
                                                                    :data-original-title="$t('subscription_helpWhereToFindSubref')"
                                                                >{{ $t("subscription_whereToFindSubRef") }}</a>
                                                            </span>
                                                        </div>
                                                        <div class="form-group validated">
                                                            <label
                                                                for="activateSubscriptionForm_activationCodeInput"
                                                            >{{ $t("subscription_activationCode") }} *</label>
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text">
                                                                        <i class="la la-chain"></i>
                                                                    </span>
                                                                </div>
                                                                <input
                                                                    type="text"
                                                                    class="form-control"
                                                                    v-model="vActivationCode"
                                                                    @input="$v.vActivationCode.$touch()"
                                                                    id="activateSubscriptionForm_activationCodeInput"
                                                                    :placeholder="$t('subscription_enterActivationCode')"
                                                                />
                                                                <div
                                                                    v-if="!$v.vActivationCode.required"
                                                                    class="invalid-feedback"
                                                                >{{ $t("error_fieldIsRequired") }}</div>
                                                            </div>
                                                            <span class="form-text text-muted">
                                                                <a
                                                                    class="kt-link"
                                                                    id="whereToFindSubscriptionActivationCodePopover"
                                                                    href="#"
                                                                    data-placement="top"
                                                                    data-toggle="kt-popover"
                                                                    data-html="true"
                                                                    title
                                                                    data-content="<img class='width100P' src='/assets/ale/whereToFindActivationCode.png' />"
                                                                    :data-original-title="$t('subscription_helpWhereToFindActivationCode')"
                                                                >{{ $t("subscription_whereToFindActivationCode") }}</a>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="kt-portlet__foot">
                                                <div
                                                    class="kt-form__actions kt-form__actions--right"
                                                >
                                                    <button
                                                        id="activateSubscriptionButton"
                                                        @click="onActivateSubscriptionButton"
                                                        type="button"
                                                        class="btn btn-brand kt-margin-r-5"
                                                        :disabled="$v.$invalid"
                                                    >
                                                        <i class="fa fa-check"></i>
                                                        {{ $t("subscription_activateNow") }}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                        <!--end::Form-->
                                    </div>
                                </div>
                            </div>

                            <!-- end:: Content -->
                        </div>
                        <!-- end:: Content -->
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                    <!-- begin:: Modal to confirm leave page -->
                    <app-confirmleavepagemodal ref="confirmleavepagemodalref"></app-confirmleavepagemodal>
                    <!-- end::Modal -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import SiteTitle from "../site/widgets/sitetitle.vue";
import moment from "moment";
import commonVueHelper from "../../helpers/commonVueHelper";
import _ct from "countries-and-timezones";

export default {
    data() {
        return {
            siteId: this.$route.params.siteId,
            locale: this.$i18n.locale,
            countries: _ct.getAllCountries(),
            vSubRef: "",
            vActivationCode: "",
            localSubscription: null,
            startDateString: "",
            dueDateString: "",
            customerCountryString: "",
            progressFromStart: null,
            requestSucceeded: false
        };
    },
    created: function() {
        console.log("Component(Subscription)::created() - called");
    },
    mounted: function() {
        console.log(
            "Component(Subscription)::mounted() - Init metronic layout"
        );
        KTLayout.init();
        $('[data-toggle="kt-popover"]').popover({ trigger: "hover" });
    },
    beforeDestroy: function() {
        console.log("Component(Subscription)::beforeDestroy() - called");
    },
    destroyed: function() {
        console.log("Component(Subscription)::destroyed() - called");
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
        vSubRef: {
            required
        },
        vActivationCode: {
            required
        }
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        siteSubscription() {
            this.requestSucceeded = true;
            if (this.siteSubscription && !_.isEmpty(this.siteSubscription)) {
                // Store entire subscription object
                this.localSubscription = this.siteSubscription;

                // Compute short date string for start date
                this.startDateString = moment(
                    this.localSubscription.startDate
                ).format(
                    commonVueHelper.getShortDateStringFormatFromLocale(
                        moment().locale()
                    )
                );

                // Compute short date string for end date
                this.dueDateString = moment(
                    this.localSubscription.endDate
                ).format(
                    commonVueHelper.getShortDateStringFormatFromLocale(
                        moment().locale()
                    )
                );

                this.customerCountryString = this.localSubscription.customerCountry;
                if (
                    this.countries[this.localSubscription.customerCountry] &&
                    this.countries[this.localSubscription.customerCountry].name
                ) {
                    this.customerCountryString = this.countries[
                        this.localSubscription.customerCountry
                    ].name;
                }

                // Comput days for percentage of progress
                var startDate = moment(this.localSubscription.startDate);
                var endDate = moment(this.localSubscription.endDate);
                var days = endDate.diff(startDate, "days");

                var now = moment();
                var daysFromStartToNow = now.diff(startDate, "days");

                var progressComputed = (daysFromStartToNow * 100) / days;
                if (progressComputed > 100) {
                    progressComputed = 100;
                    this.progressFromStart = progressComputed.toFixed(1);
                } else if (progressComputed == 0) {
                    this.progressFromStart = progressComputed;
                } else {
                    this.progressFromStart = progressComputed.toFixed(1);
                }
            }

            $('[data-toggle="kt-popover"]').popover({ trigger: "hover" });
        },

        user(user) {
            // In case of language change
            if (user && this.localSubscription) {
                // Format short date string for start date
                this.startDateString = moment( this.localSubscription.startDate ).format( commonVueHelper.getShortDateStringFormatFromLocale( moment().locale() ) );
                // Format short date string for end date
                this.dueDateString = moment( this.localSubscription.endDate ).format( commonVueHelper.getShortDateStringFormatFromLocale( moment().locale() ) );
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters([
            "siteSubscription",
            "getActiveLicense",
            "getWaitingForActivationAddonLicense",
            "getWaitingForActivationRenewalLicense",
            "user"
        ]),

        isRenewalCanBeActivated() {
            if (
                this.getWaitingForActivationRenewalLicense &&
                this.siteSubscription
            ) {
                var today = moment();
                var endDateSubscription = moment(this.siteSubscription.endDate);

                if (
                    today.isSame(endDateSubscription, "day") ||
                    today.isAfter(endDateSubscription)
                ) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions([
            "activateSubscription",
            "createTrialSubscription",
            "activateAddon",
            "activateRenewal"
        ]),

        onActivateSubscriptionButton() {
            console.log(
                "Component(Subscription)::onActivateSubscriptionButton() - called"
            );
            const data = {
                siteId: this.siteId,
                activationCode: this.vActivationCode,
                subRef: this.vSubRef
            };
            this.activateSubscription(data);
        },

        startTrialSubscriptionButton() {
            console.log(
                "Component(Subscription)::startTrialSubscriptionButton() - called"
            );
            const data = {
                siteId: this.siteId
            };
            this.createTrialSubscription(data);
        },

        onActivateAddon() {
            console.log("Component(Subscription)::onActivateAddon() - called");
            const data = {
                siteId: this.siteId
            };
            this.activateAddon(data);
        },

        onActivateRenewal() {
            console.log(
                "Component(Subscription)::onActivateRenewal() - called"
            );
            const data = {
                siteId: this.siteId
            };
            this.activateRenewal(data);
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-sitetitle": SiteTitle
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
</style>
